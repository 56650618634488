import React from 'react'

import From from './Form'

const App = () => {
  const { pathname } = window.location
  const id = pathname.substring(1)
  if (!id.length) {
    return '404 Not Found'
  }

  return (
    <From id={id} />
  )
}

export default App
