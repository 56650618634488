import React, {Component} from 'react'

import apiRequest from '../Api/requests'
import Api from '../Api'
import { getFirstPartOfSum, getLastPartOfSum, isValidUrl } from '../Constants/helpers'

import Card from './img/methods/card.svg'
// import Qiwi from './img/methods/qiwi.svg'
// import Webmoney from './img/methods/webmoney.svg'

import Presenter from './presenter'

class Form extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isFormLoading: false,
      isErrorId: false,
      isShowGlobalError: false,
      isMethodMenuActive: false,
      currentMethodId: 'card',
      isHelpShow: false,
      amount: '0',
      tax: '0',
    }
  }

  methods = [
    {
      id: 'card',
      logo: Card,
      alt: 'card',
      text: 'Банковская карта',
    },
    // {
    //   id: 'qiwi',
    //   logo: Qiwi,
    //   alt: 'qiwi',
    //   text: 'Qiwi',
    // },
    // {
    //   id: 'webmoney',
    //   logo: Webmoney,
    //   alt: 'webmoney',
    //   text: 'WebMoney',
    // },
  ]

  componentDidMount() {
    this.getPaymentData()
    document.title = `Оплата заказа №${this.props.id}`
  }

  showGlobalError = () => {
    this.setState({ isShowGlobalError: true })
  }

  getPaymentData = () => {
    const { id } = this.props

    apiRequest({
      dispatchFunctions: {
        success: (payment) => {
          const {
          amount: {
            value,
          },
            initiated,
            status,
            description,
          } = payment
          document.title = description
          if (initiated && status === 'pending') {
            const { confirmation } = payment
            if (confirmation.confirmation_url) {
              window.location.replace(confirmation.confirmation_url)
            } else if (confirmation.submit_url) {
              window.location.replace(confirmation.submit_url)
            }
          }
          if (status !== 'pending') {
            const { confirmation, domain } = payment
            if (confirmation && confirmation.return_url) {
              if (isValidUrl(confirmation.return_url)) {
                window.location.replace(confirmation.return_url)
              }
            }
            if (domain && isValidUrl(domain)) {
              window.location.replace(domain)
            }
          }
          let amount = getFirstPartOfSum(value)
          if (getLastPartOfSum(value) !== '00') {
            amount = `${amount}.${getLastPartOfSum(value)}`
          }
          this.setState({ amount })
          if (payment.metadata) {
            const {
              metadata: {
                refill_id: refillId,
                service_fee: serviceFee,
              },
            } = payment
            let tax = getFirstPartOfSum(serviceFee)
            if (getLastPartOfSum(serviceFee) !== '00') {
              tax = `${tax}.${getLastPartOfSum(serviceFee)}`
            }
            this.setState({ tax })
          }
        },
        error: (errors) => {
          this.setState({
            errors,
          }, this.focusOnError)
        },
      },
      request: values => Api.getPayment(values),
      values: {
        id,
      },
      errorTitle: 'Ошибка входа',
      errorCallback: () => {
        this.setState({ isErrorId: true })
      },
    })
  }

  methodMenuToggle = () => {
    this.setState(prevState => ({ isMethodMenuActive: !prevState.isMethodMenuActive }))
  }

  changeCurrentMethod = (methodId) => {
    this.setState({
      currentMethodId: methodId,
      isMethodMenuActive: false,
    })
  }

  showHelp = () => {
    this.setState({ isHelpShow: true })
  }

  hideHelp = () => {
    this.setState({ isHelpShow: false })
  }

  onLoadingStart = () => this.setState({ isFormLoading: true })

  onLoadingEnd = () => this.setState({ isFormLoading: false })

  render() {
    const {
      methods,
      methodMenuToggle,
      changeCurrentMethod,
      showHelp,
      hideHelp,
      showGlobalError,
      onLoadingStart,
      onLoadingEnd,
    } = this
    const {
      isFormLoading,
      isErrorId,
      isShowGlobalError,
      currentMethodId,
      isMethodMenuActive,
      isHelpShow,
      amount,
      tax,
    } = this.state
    const { id } = this.props
    const currentMethod = methods.find(element => element.id === currentMethodId)

    if (isErrorId) {
      return '404 Not Found'
    }

    let allSum = (Number(amount) - Number(tax))
    if (getLastPartOfSum(allSum) !== '00') {
      allSum = allSum.toFixed(2)
    }

      return (
      <Presenter
        {...{
          id,
          methods,
          methodMenuToggle,
          changeCurrentMethod,
          onLoadingStart,
          onLoadingEnd,
          isFormLoading,
          showHelp,
          hideHelp,
          showGlobalError,
          currentMethodId,
          currentMethod,
          isMethodMenuActive,
          isHelpShow,
          isShowGlobalError,
          amount,
          tax,
          allSum,
        }}
      />
    )
  }
}

export default Form