import React from 'react'
import classNames from 'classnames'

// import cvv from './img/cvv.svg'

import CardForm from './Card'

import styles from './form.module.scss'

export default function Presenter({
  id,
  // methods,
  // methodMenuToggle,
  // changeCurrentMethod,
  onLoadingStart,
  onLoadingEnd,
  isFormLoading,
  showHelp,
  hideHelp,
  showGlobalError,
  // currentMethodId,
  currentMethod,
  // isMethodMenuActive,
  isHelpShow,
  isShowGlobalError,
  amount,
  tax,
  allSum,
}) {
  const details = (isAside) => (
    <div className={classNames(styles.detailSummary, isAside && styles.detailSummary_aside)}>
      <div className={styles.detailSummary_title}>Сумма</div>
      <div className={styles.detailSummary_item}>
        <div className={styles.detailSummary_name}>Цена</div>
        <div className={styles.detailSummary_sum}>
          {`${allSum} `}
          <span className="currencyFont">₽</span>
        </div>
      </div>
      <div className={styles.detailSummary_item}>
        <div className={styles.detailSummary_name}>
          {`Сервисный сбор `}
          <i
            className={styles.detailSummary_help}
            onMouseEnter={showHelp}
            onMouseLeave={hideHelp}
          />
          <div
            className={classNames(
              styles.tooltip,
              Number(tax) > 0 && styles.tooltipBig,
              isHelpShow && styles.active
            )}
          >
            {Number(tax) > 0
              ? 'Помогает обеспечивать работу Kwork 24/7, оплачивать платежные комиссии и развивать маркетплейс. Сервисный сбор составляет 5% от суммы пополнения, но не менее 200 ₽'
              : 'Помогает обеспечивать работу Kwork 24/7, оплачивать платежные комиссии и развивать маркетплейс.'
            }
            <i className={styles.tooltip_arrow} />
          </div>
        </div>
        <div className={styles.detailSummary_sum}>
          {`${tax} `}
          <span className="currencyFont">₽</span>
        </div>
      </div>
      <div className={styles.detailSummary_line} />
      <div className={classNames(styles.detailSummary_item, styles.detailSummary_itemTotal)}>
        <div className={styles.detailSummary_name}>Итого:</div>
        <div className={styles.detailSummary_sum}>
          {`${amount} `}
          <span className="currencyFont">₽</span>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <div className={styles.header_inner}>
            <a
              href="https://kwork.ru/settings"
              target="_blank"
              rel="noreferrer"
              className={styles.header_logo}
            />
          </div>
        </header>
        {isShowGlobalError && (
          <div className={styles.message}>
            <div className={styles.message_item}>
              Временная ошибка, попробуйте выполнить запрос позднее
            </div>
          </div>
        )}
        <div className={styles.layout}>
          <div className={styles.form}>
            <div className={styles.form_title}>
              <div className={styles.form_titleText}>Пополнение баланса</div>
              <div className={styles.form_titleMethod}>
                <div className={styles.pmet}>
                  <div className={styles.pmet_link}>
                    <img src={currentMethod.logo} alt={currentMethod.alt} className={styles.pmet_logo} />
                    <span className={styles.pmet_text}>{currentMethod.text}</span>
                    {/*<span className={styles.pmet_corner} />*/}
                  </div>
                  {/*
                  <div className={classNames(styles.pmet_menu, isMethodMenuActive && styles.show)}>
                    {methods.map(method => (
                      <a
                        key={method.id}
                        className={classNames(styles.pmet_item, method.id === currentMethodId && styles.active)}
                        onClick={() => changeCurrentMethod(method.id)}
                      >
                        <img src={method.logo} alt={method.alt} className={styles.pmet_logo} />
                        {method.text}
                      </a>
                    ))}
                  </div>
                  */}
                </div>
              </div>
            </div>
            <div className={classNames(styles.form_content, isFormLoading && styles._loading)}>
              {details(false)}
              <CardForm
                id={id}
                showGlobalError={showGlobalError}
                allSum={amount}
                onLoadingStart={onLoadingStart}
                onLoadingEnd={onLoadingEnd}
              />
              <div className={styles.form_footer}>
                <div className={styles.form_link}>
                  <a
                    href="mailto:support@kwork.ru"
                    className={classNames(styles.form_link_item, styles.form_link_itemSupport)}
                  >
                    Написать в поддержку
                  </a>
                </div>
              </div>
              <div className={styles.spinner} />
            </div>
            <div className={styles.form_verify}></div>
          </div>
          <div className={styles.details}>
            {details(true)}
            <div className={styles.guarantee}>
              <div className={styles.guarantee_title}>
                <i className={styles.guarantee_icon} />
                {` Гарантия безопасности заказа `}
              </div>
              <div className={styles.guarantee_text}>
                {'Ваши средства под защитой Kwork на время выполнения заказа. Продавец получает оплату после того, как вы проверите и примите заказ. Гарантия возврата 100% в случае просрочки или невыполнения заказа.'}
              </div>
            </div>
          </div>
          <div className={styles.verify} />
        </div>
      </div>
      <footer className={styles.f}>
        <div className={styles.f_copy}>{`© Kwork.ru ${new Date().getFullYear()}`}</div>
      </footer>
    </>
  )
}