import React from 'react'
import classNames from 'classnames'

import cvv from './img/cvv.svg'

import styles from './card.module.scss'

export default function Presenter({
  numberRef,
  expiryRef,
  cscRef,
  onInputChange,
  toggleCheck,
  isChecked,
  isNumberError,
  isExpiryError,
  isCSCError,
  isNumberValid,
  isDateValid,
  number,
  cardType,
  expiry,
  csc,
  submitForm,
  allSum,
}) {
  return (
    <div className={styles.wrap}>
      <div className={styles.body}>
        <div className={styles.selector}>
          <div className={styles.input}>
            <label className={styles.label}>Номер карты</label>
            <input
              ref={numberRef}
              required
              placeholder="0000 0000 0000 0000"
              display_label="hidden"
              minLength="16"
              autoFocus="autofocus"
              autoComplete="cc-number"
              data-require="card_number"
              inputMode="numeric"
              name="number"
              value={number}
              onChange={onInputChange}
              className={classNames(
                styles.input_item,
                isNumberValid && styles.is_valid,
                isNumberError && styles.is_invalid,
                cardType === 'mir' && styles._mir,
                cardType === 'visa' && styles._visa,
                cardType === 'maestro' && styles._maestro,
                cardType === 'mastercard' && styles._mastercard,
                cardType === 'unknown' && styles._unknown,
              )}
            />
            <div className={classNames( styles.input_error, styles.is_left, isNumberError && styles.is_active)}>
              {isNumberError && 'Введите номер карты'}
            </div>
          </div>
        </div>
        <div className={styles.input_inner}>
          <div className={styles.input}>
            <label className={styles.label}>Срок</label>
            <input
              ref={expiryRef}
              required
              placeholder="ММ/ГГ"
              display_label="hidden"
              autoComplete="cc-exp"
              data-ignore-date-expire="1"
              data-require="card_expire"
              maxLength={5}
              name="expiry"
              value={expiry}
              onChange={onInputChange}
              className={classNames(styles.input_item, isDateValid && styles.is_valid, isExpiryError && styles.is_invalid)}
            />
            <div className={classNames(styles.input_error, styles.is_left, isExpiryError && styles.is_active)}>
              {isExpiryError && 'Укажите срок действия'}
            </div>
          </div>
          <div className={styles.input}>
            <label className={styles.label}>CVC-код</label>
            <input
              ref={cscRef}
              required
              placeholder="***"
              display_label="hidden"
              autoComplete="cc-csc"
              inputappend="?"
              maxLength="3"
              minLength="3"
              inputMode="numeric"
              name="csc"
              value={csc}
              onChange={onInputChange}
              className={classNames(styles.input_item, csc.length === 3 && styles.is_valid, isCSCError && styles.is_invalid)}
            />
            <div className={classNames(styles.input_error, styles.is_right, isCSCError && styles.is_active)}>
              {isCSCError && (
                <>
                  CVC/CVV код — это три цифры на оборотной стороне карты
                  <img src={cvv} alt="cvv" />
                </>
              )}
            </div>
          </div>
        </div>
        {/*
        <div className={styles.input_saveCard}>
          <label className={styles.checkbox}>
            <input
              type="checkbox"
              className={styles.checkbox_item}
              name="cards_cfk[card][needRemember]"
              checked={isChecked}
              onChange={toggleCheck}
            />
            <span className={styles.checkbox_checker} />
            <span className={styles.checkbox_label}>{` Запомнить карту для следующих покупок `}</span>
          </label>
        </div>
        */}
        <div className={styles.buttons}>
          <button className={styles.button} onClick={submitForm}>
            <span>
              {`Оплатить\u00A0${allSum} `}
              <span className="currencyFont">₽</span>
            </span>
          </button>
        </div>
        <div className={styles.paymentComment}>
          <i className={styles.paymentComment_lock} />
          {` Платеж защищен сертификатом `}
          <span className={styles.paymentComment_accent}>TLS</span>
          {` и протоколом `}
          <span className={styles.paymentComment_accent}>3D Secure</span>
        </div>
      </div>
    </div>
  )
}