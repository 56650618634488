export const getFirstPartOfSum = (sum) => {
  if (sum) {
    return parseInt(sum, 10).toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1').slice(0, -2)
  }
  return '0'
}

export const getLastPartOfSum = (sum) => {
  if (sum) {
    const lastPart = sum.toString().split('.')[1]
    if (!lastPart) {
      return '00'
    }
    if (lastPart.length === 1) {
      return lastPart.concat('0')
    }
    return lastPart
  }
  return '00'
}

export const isValidUrl = (string) => {
  try {
    // eslint-disable-next-line no-new
    new URL(string)
  } catch (_) {
    return false
  }

  return true
}
