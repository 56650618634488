// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  dispatchFunctions = {
    success: () => {},
    error: () => {},
  },
  request = () => {},
  values = {},
  errorTitle = '',
  callback = () => {},
  successCallback = () => {},
  errorCallback = () => {},
}) => {
  request(values).end((err, res) => {
    if (!res) {
      callback()
      return
    }

    if (res.statusCode === 500) {
      errorCallback()
    }

    if (res.statusCode === 404) {
      errorCallback()
    }

    if (res.statusCode !== 200) {
      const error = res.body
      if (error && error.violations) {
        dispatchFunctions.error(error.violations)
      }
      if (error && !error.violations) {
        errorCallback()
      }
      callback()
      return
    }

    if (res.statusCode === 200 || res.ok) {
      if (res.body) {
        dispatchFunctions.success(res.body)
      }
      callback()
      successCallback()
    }
  })
}
