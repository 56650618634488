import React, {Component} from 'react'
import cardValidator from 'card-validator'
import moment from 'moment'

import apiRequest from '../../Api/requests'
import Api from '../../Api'
import { isValidUrl } from '../../Constants/helpers'

import Presenter from './presenter'

class Card extends Component {
  constructor() {
    super()

    this.numberRef = React.createRef()
    this.expiryRef = React.createRef()
    this.cscRef = React.createRef()

    this.state = {
      isChecked: true,
      isNumberError: false,
      isExpiryError: false,
      isCSCError: false,
      errors: [],
      number: '',
      expiry: '',
      csc: '',
      cardType: '',
      isFormValid: false,
      isNumberPotentiallyValid: true,
      isNumberValid: false,
      isDateValid: false,
    }

    cardValidator.creditCardType.addCard({
      niceType: 'Mir',
      type: 'mir',
      patterns: [[2200, 2204], 9],
      gaps: [4, 8, 12],
      lengths: [16, 17, 18, 19],
      code: {
        name: 'CVV',
        size: 3,
      },
    })
  }

  toggleCheck = () => {
    this.setState(prevState => ({ isChecked: !prevState.isChecked }))
  }

  getLastPartOfErrorName = (string) => {
    const split = string.split('.')
    return split[split.length - 1]
  }

  // handleInputKeyDown = (e) => {
  //   if (e.keyCode === 13) {
  //     e.preventDefault()
  //     // this.props.handleSubmitForm()
  //   }
  // }

  beforeNumberStateChange = ({ previousState, nextState }) => {
    // это исправлени для бага, при котором удаляутся последние 4 цифры при автозаполнении
    // если не сохранен cvv код карты
    if (previousState && nextState) {
      if (previousState.value.length - nextState.value.length === 3) {
        return { ...previousState }
      }
    }

    let { value } = nextState
    if (value.endsWith(' ')) {
      value = value.slice(0, -1)
    }
    return { ...nextState, value }
  }

  onInputChange = (e) => {
    const { name, value } = e.target
    let newValue = value.replace(/\D/g, '')
    if (name === 'number') {
      // // TODO: исправить этот ужас!
      // // это исправление для бага, при котором удаляутся последние 4 цифры при автозаполнении
      // // если не сохранен cvv код карты
      // if (this.state.number.length === newValue.length) {
      //   console.log('test')
      //   return
      // }
      if (value.length > 23) {
        return
      }

      const cardValue = this.numberRef.current.value.replace(/\s/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,7})/)
      const numbers = !cardValue[2].length ? cardValue[1]
        : `${cardValue[1]} ${cardValue[2]}${`${cardValue[3] ? ` ${cardValue[3]}` : ''}`}${`${cardValue[4] ? ` ${cardValue[4]}` : ''}`}`

      this.numberChangeValidation(numbers)
    } else if (name === 'expiry') {
      newValue = value.replace(/\D/g, '')
      if (value.length > 3) {
        newValue = value
      }
      if (/^\d$/.test(value) && (value !== '0' && value !== '1')) {
        newValue = `0${value}/`
      }
      if (/^\d\d$/.test(value)) {
        newValue = `${value}/`
        if (value === '00') {
          newValue = `01/`
        }
      }
      this.expiryValidation(newValue)
    } else if (name === 'csc') {
      if (value.length > 3) {
        return
      }
      this.cscChangeValidation(newValue)
    }
  }

  getNumberMask = (cardType = '') => {
    switch (cardType) {
      case 'american-express':
        return '9999 999999 99999'
      case 'maestro':
        return '99999999 99999999999'
      case 'diners-club':
        return '9999 999999 9999'
      case 'visa':
        return '9999 9999 9999 9999'
      case 'mastercard':
        return '9999 9999 9999 9999'
      case 'mir':
        return '9999 9999 9999 9999999'
      case 'discover':
        return '9999 9999 9999 9999'
      case 'jcb':
        return '9999 9999 9999 9999'
      case 'unionpay':
        return '9999 9999 9999 9999'
      default:
        return '9999 9999 9999 9999'
    }
  }

  isChangeFocusFromNumber = (value = '', isValid = false) => {
    const noSpacesValueLength = value.replace(/\s+/g, '').length
    return (noSpacesValueLength === 16 || noSpacesValueLength === 19) && isValid
  }

  numberChangeValidation = (value = '', callback = () => {}) => {
    const validNumber = cardValidator.number(value)

    // const newNumberMask = this.getNumberMask(validNumber.card ? validNumber.card.type : '')
    let cardType = validNumber.card ? validNumber.card.type : 'unknown'
    if (!value.length) {
      cardType = ''
    }

    if (validNumber.isValid) {
      this.setState({
        isNumberError: false,
        errors: this.state.errors.map((errors) => {
          if (errors.parameter === 'customer.card.number') {
            return null
          }
          return errors
        }).filter(Boolean),
      })
    }

    this.setState({
      number: value,
      cardType,
      isNumberPotentiallyValid: validNumber.isPotentiallyValid,
      isNumberValid: validNumber.isValid,
      // numberMask: newNumberMask,
    }, () => {
      callback()
      if (value.length >= 19 && !validNumber.isPotentiallyValid) {
        this.setState({ isNumberError: true })
      }
      if (this.isChangeFocusFromNumber(value, validNumber.isValid)) {
        this.setState({ isNumberError: false })
        this.expiryRef.current.focus()
      }
    })
  }

  expiryValidation = (expiry = '', callback = () => {}) => {
    let isDateValid = false
    let dateValidStatus = ''
    if (expiry.length === 5) {
      const enterDate = moment(
        `${expiry[0] + expiry[1]}/${expiry[expiry.length - 2] + expiry[expiry.length - 1]}`,
        'MM/YY'
      )
      const diffDate = enterDate.diff(moment())
      isDateValid = diffDate > 0
      dateValidStatus = diffDate > 0 ? '' : 'error'
    }
    if (isDateValid) {
      this.setState({
        isExpiryError: false,
        errors: this.state.errors.map((errors) => {
          if ((errors.parameter === 'customer.card.expiry_month') || (errors.parameter === 'customer.card.expiry_year')) {
            return null
          }
          return errors
        }).filter(Boolean),
      })
    }
    this.setState({
      expiry,
      isDateValid,
      dateValidStatus,
    }, () => {
      callback()
      if (expiry.length === 5 && isDateValid) {
        this.cscRef.current.focus()
      }
    })
  }

  cscChangeValidation = (csc = '', callback = () => {}) => {
    this.setState({ csc }, () => {
      callback()
      if (csc.length === 3) {
        this.setState({
          isCSCError: false,
          errors: this.state.errors.map((errors) => {
            if (errors.parameter === 'customer.card.csc') {
              return null
            }
            return errors
          }).filter(Boolean),
        })
        this.cscRef.current.blur()
      }
    })
  }

  setInputError = () => {
    const { errors } = this.state
    const {
      number,
      isNumberPotentiallyValid,
      expiry,
      isDateValid,
      csc,
    } = this.state

    if (
      !number.length
      || !isNumberPotentiallyValid
      || !!errors.find(error => this.getLastPartOfErrorName(error.parameter) === 'number')
    ) {
      this.setState({ isNumberError: true })
    }

    if (
      !expiry.length
      || (!isDateValid && expiry.length === 5)
      || (
        !!errors.find(error => this.getLastPartOfErrorName(error.parameter) === 'expiry_month')
        || !!errors.find(error => this.getLastPartOfErrorName(error.parameter) === 'expiry_year')
      )
    ) {
      this.setState({ isExpiryError: true })
    }

    if (
      !csc.length
      || csc.length !== 3
      || !!errors.find(error => this.getLastPartOfErrorName(error.parameter) === 'csc')
    ) {
      this.setState({ isCSCError: true })
    }
  }

  submitForm = () => {
    const {
      number,
      expiry,
      csc,
      errors,
    } = this.state
    const { id } = this.props

    // не даёт отправить форму если есть ошибки
    if (errors.length > 0) {
      return
    }

    this.props.onLoadingStart()

    const miniExpiry = expiry.replace(/\s/g, '')
    const card = {
      number: number.replace(/\s/g, ''),
      expiry_month: miniExpiry[0] + miniExpiry[1],
      expiry_year: miniExpiry[3] + miniExpiry[4],
      csc,
    }

    const {
      screen: {
        colorDepth,
        width,
        height,
      },
      innerHeight,
      innerWidth,
    } = window
    const {
      language,
      userAgent,
    } = navigator

    const browser = {
      accept: '*/*',
      timezone_offset: new Date().getTimezoneOffset(),
      color_depth: colorDepth,
      screen_width: width,
      screen_height: height,
      language,
      user_agent: userAgent,
      window_width: innerWidth,
      window_height: innerHeight,
      java_enabled: true,
    }

    apiRequest({
      dispatchFunctions: {
        success: (payment) => {
          const {
            initiated,
            status,
          } = payment
          if (initiated && status === 'pending') {
            const { confirmation } = payment
            if (confirmation.confirmation_url) {
              window.location.replace(confirmation.confirmation_url)
            } else if (confirmation.submit_url) {
              window.location.replace(confirmation.submit_url)
            }
          }
          if (status !== 'pending') {
            const { confirmation, domain } = payment
            if (confirmation && confirmation.return_url) {
              if (isValidUrl(confirmation.return_url)) {
                window.location.replace(confirmation.return_url)
              }
            }
            if (domain && isValidUrl(domain)) {
              window.location.replace(domain)
            }
          }
        },
        error: (errors) => {
          this.setState({
            errors,
          }, this.setInputError)
        },
      },
      request: values => Api.sendPayment(values),
      values: {
        id,
        data: {
          settlement_method: 'card',
          browser,
          card,
        },
      },
      callback: () => {
        this.props.onLoadingEnd()
      },
      errorTitle: 'Ошибка ввода',
      errorCallback: () => {
        this.props.showGlobalError()
      },
    })
  }

  render() {
    const {
      toggleCheck,
      numberRef,
      expiryRef,
      cscRef,
      beforeNumberStateChange,
      onInputChange,
      submitForm,
    } = this
    const {
      isChecked,
      isNumberError,
      isExpiryError,
      isCSCError,
      isNumberValid,
      isDateValid,
      number,
      cardType,
      expiry,
      csc,
    } = this.state
    const { allSum } = this.props

    return (
      <Presenter
        {...{
          numberRef,
          expiryRef,
          cscRef,
          isNumberValid,
          isDateValid,
          toggleCheck,
          isChecked,
          beforeNumberStateChange,
          onInputChange,
          isNumberError,
          isExpiryError,
          isCSCError,
          number,
          cardType,
          expiry,
          csc,
          submitForm,
          allSum,
        }}
      />
    )
  }
}

export default Card